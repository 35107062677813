<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

      <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Pigment List</h5>

      <div class="header-elements">
        <form action="#">
          <div class="form-group-feedback form-group-feedback-right">
            <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
            <div class="form-control-feedback">
              <i class="icon-search4 font-size-base text-muted"></i>
            </div>
          </div>
        </form>

      </div>
    </div>


    <div class="table-responsive">
      <table id="mytable"
             class="table  table-hover  table-bordered table-condensed table-columned"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <th data-field="name" data-sortable="true">Name</th>
          <th data-field="status" data-sortable="true">Status</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
        <a href="#" class="dropdown-item" ><i class="icon-pencil7" style="color: #2d7330"></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" ><i class="icon-blocked" style="color: orangered"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->

      <FocusTrap>
        <vmodal name="pigment_modal_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="600" :height="270" @click="showModal" >
          <PigmentForm v-bind:pigment_prop="pigment" v-on:pigment_saved="loadData" v-on:pigment_window_closed="closeModal" ></PigmentForm>
        </vmodal>
      </FocusTrap>
    <!-- End of Modal Window -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

  </div>
  </FocusTrap>
</template>

<script>
  import PigmentForm from '@/views/fms/pigment/PigmentForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'PigmentView',
    components: {
      PigmentForm,
      FabButton,
      moment
    },
    data () {
      return {
        readonly: false,
        pigment: JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}'),
        mytable: {},
      }
    },
    created () {

    },
    mounted () {
      let self = this;
      this.$data.mytable = $('#mytable');
      this.$data.mytable.bootstrapTable();

      $('[data-toggle="popover"]').popover();
      $('#mytable').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {

          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === "Refresh") {
            self.loadData();
          }else if($(e.target).text() === 'Modify'){
            self.modifyDocument(id);
          }else if($(e.target).text() == 'Remove'){
            self.removeDocument(id);
          }

        }
      });

      $('.select-search').select2();

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#mytable>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });


      $('#pigment_modal_window').on('shown.bs.modal', function () {
        $('#txtname').trigger('focus');
      });

      $('#pigment_modal_window').on('hide.bs.modal', function () {
        self.clear();
      });

      $('[data-action=remove]').click(function (e) {
        $('#pigment_modal_window').modal('hide');
      });

      self.loadData();

    },
    methods: {
      closeModal() {
        this.$modal.hide('pigment_modal_window')
      },
      beforeOpen(){
        console.log('beforeOpen');
      },
      beforeClose(){
        this.loadData();
      },
      showModal () {
        this.pigment= JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}');
        this.$modal.show('pigment_modal_window');
      },
      clear(){
        this.pigment= JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}');
      },
      modifyDocument (id) {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.pigment = {};
        fetch(`${process.env.VUE_APP_ROOT_API}api/pigment/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            self.$data.pigment = resp.data;
            self.$modal.show('pigment_modal_window');
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
        });
      },
      removeDocument (id) {
        let self = this;

        self.$data.pigment.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.pigment)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete this!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}api/pigment`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('pigment_modal_window');
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });

      },
      loadData () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        this.$modal.hide('pigment_modal_window');

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}api/pigments/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if( _.isArray(resp.data)){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $(self.$data.mytable).unblock();
        });
      }
    }
  }
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }

  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
