<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Pigment Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter Pigment Name here..." maxlength="100" v-if="pigment" v-model="pigment.name" required autofocus autocomplete="off" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Status:</label>
          <div class="col-md-10">
            <select class="form-control" v-if="pigment" v-model="pigment.status">
              <option value="Active" selected>Active</option>
              <option value="Disabled">Disabled</option>
              <option value="Suspended">Suspended</option>
            </select>
<!--            <input type="text" class="form-control" placeholder="Enter print name here..." maxlength="100" v-if="pigment" v-model="pigment.status">-->
          </div>
        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>

  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'PigmentForm',
    data () {
      return {
        pigment: JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}')
      }
    },
    component: {

    },
    props: {
      pigment_prop: {
        type: Object,
        default: () => JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}')
      }
    },
    beforeMount () {
      this.pigment = this.pigment_prop; // save props data to itself's data and deal with tms
    },
    created () {
      this.$data.pigment = JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}')
    },
    mounted () {

    },
    methods: {
      closeWindow() {
        this.$emit('pigment_window_closed');
      },
      saveDocument(){
        const self = this;

        if(self.$data.pigment.name.trim().length < 1){
          alert("Invalid Name");
          return
        }

        self.$data.pigment.type = 0;

        const requestOptions = {
          method:  (self.$data.pigment.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.pigment)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}api/pigment`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.ok) {
            swal ( { title: "Success!" ,  text: data.msg, type:  "success", timer:1500} );
            self.pigment = JSON.parse('{"id":0,"type":0,"name":"","status":"Active"}');
            $('#txtname').focus();
            self.$emit('pigment_saved');

          } else {
            swal ( { title: "Oops!" ,  text: data.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
